<i18n src="@/locales/article89.json" />

<template>
  <div class="fixed-background">
    <header class="columns is-centered">
      <div class="column is-9">
        <h1 class="has-background-primary-light-opacity mt-2">
          {{ $t('h1_title') }}
        </h1>
        <img
          src="@/assets/img/articles/article89/infections-dentaire-risque-cardiaque.jpg"
          alt=""
        >
      </div>
    </header>
    <ImanTestimonialMessage type="is-info">
      <div v-html="$t('message1')" />
    </ImanTestimonialMessage>
    <ImanTestimonialMessage type="is-info">
      <div v-html="$t('message2')" />
    </ImanTestimonialMessage>
    <ImanTestimonialArticle :title="$t('article1_title')">
      <div class="content">
        <h3 class="has-text-primary is-size-5 my-3">
          {{ $t('article1_h3_1') }}
        </h3>
        <p>
          {{
            $t('article1_p1')
          }}
        </p>
        <h3 class="has-text-primary is-size-5 my-3">
          {{ $t('article1_h3_2') }}
        </h3>
        <p>
          {{
            $t('article1_p2')
          }}
        </p>
        <p>
          {{
            $t('article1_p3')
          }}
        </p>
      </div>
    </ImanTestimonialArticle>
    <ImanTestimonialMessage type="is-info">
      <div v-html="$t('message3')" />
    </ImanTestimonialMessage>
    <ImanTestimonialArticle :title="$t('article2_title')">
      <div class="content">
        <p>
          {{
            $t('article2_p1')
          }}
        </p>
        <div v-html="$t('article2_p2')" />
      </div>
    </ImanTestimonialArticle>
  </div>
</template>

<script>
import {metaMixin} from "@/mixins/meta-mixin";

export default {
  name: "Article89",
  mixins: [metaMixin],
  props: {
    locale: {
      type: String,
      default: 'en'
    }
  },
  data () {
    return {
      video: {
        src: 'https://www.youtube.com/embed/76l6Uj9uB9c'
      }
    }
  },
  computed: {
    openGraphImgPath () {
      return 'https://anveli.dental' + require('../../assets/img/thumbnail/risques-cardiaque-infections-dentaires.png')
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/style/scss/variables';
h1 {
  font-family: 'Comfortaa', cursive;
  color: $white;
  font-size: 1.7rem;
  font-weight: lighter;
  line-height: $title-line-height;
  padding: 0.5rem;
}
</style>
